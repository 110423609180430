<template>
<!-- 国安账单demo -->
  <div class="height-100" :class="isRed ? 'redbg' : 'isyello'">
    <div class="audio_box">
      <audio
        src="http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/yinhe/RaisingMeHigher.mp3"
        loop="loop"
        ref="bgAudio"
      ></audio>
    </div>
    <div class="card animate__animated animate__fadeIn duration " id="card1">
      <div class="bg_content">
        <div class="content_top">
          <img src="../../assets/img/bg103_3.png" alt="">
        </div>
        <div class="content_middle">
          <h3>2021年</h3>
          <p>国金AI助你揭秘投资账单！</p>
        </div>
        <div class="content_btn" @click="startPlay()">接收账单</div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/logo103.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card2">
      <div class="content">
        <p>财主,</p>
        <p>你都忘了吧，这是我们相识1365天的日子~</p>
        <p>你有挂在嘴边的灿烂，</p>
        <p>有埋在心底的无奈，</p>
        <p>但总能找到快乐的点滴。</p>
        <p>国金证券陪你回顾这些天 </p>
        <p>2021年</p>
        <p>···</p>
      </div>
      <div class="content_bg">
        <img src="../../assets/img/bg103_5.png" alt="">
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card3">
      <div class="content_bg"></div>
      <div class="content">
        <p>2021年， </p>
        <p>全年收益35323.57元，</p>
        <p>收益率23.57%，</p>
        <p>战胜了78.71%的投资者，</p>
        <p> 武汉地区排名178名。</p>
      </div>
      <div class="content_bottom">
        <p><span>年度收益率走势</span><span>2021</span></p>
        <p><span>沪深300: 18.52%</span><span>我：23.57%</span></p>
        <div id="chart1"></div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card4">
      <div class="content_top">
        <img src="../../assets/img/bg103_8.png" alt="">
      </div>
     <div class="content">
       <p>厉害了我的哥~女明星；</p>
       <p>这水平不去当投资顾问可惜了</p>
       <p>比去年多赚了33745.22元</p>
     </div>
     <div class="content_box">
       <div>  
         <p>2021年收益</p>
         <div class="bg_progess" @click="boxClick(1)">
           <p>35323.57</p>
           <i class="down"></i>
           <i class="power"></i>
         </div>
       </div>
       <div>  
         <p>2020年收益</p>
         <div class="bg_progess" @click="boxClick(2)">
           <p>1578.35</p>
           <i class="down"></i>
         </div>
       </div>
       <div>  
         <p>2019年收益</p>
         <div class="bg_progess" @click="boxClick(3)">
           <p>12356.57</p>
           <i class="down" ></i>
         </div>
       </div>
       <div>  
         <p>2018年收益</p>
         <div class="bg_progess" @click="boxClick(4)">
           <p>-3578</p>
           <i class="down" ></i>
         </div>
       </div>
       <div class="flot_box" v-show="boxState">        
          <div class="flot_son" v-show="boxNum == 1">
            <p>
              <span>2021年收益构成</span>
              <i @click="boxClose(0)"></i>
            </p>
            <p class="data">
              <span>股票</span>
              <span>+18753.53</span>
            </p>
            <p class="data">
              <span>基金</span>
              <span>+9572.35</span>
            </p>
            <p class="data">
              <span>债券</span>
              <span>+3005.62</span>
            </p>
            <p class="data">
              <span>理财</span>
              <span>+2413.72</span>
            </p>
          </div>
          <div class="flot_son" v-show="boxNum == 2">
            <p>
              <span>2020年收益构成</span>
              <i @click="boxClose(0)"></i>
            </p>
            <p class="data">
              <span>股票</span>
              <span>+15453.53</span>
            </p>
            <p class="data">
              <span>基金</span>
              <span>+9122.35</span>
            </p>
            <p class="data">
              <span>债券</span>
              <span>+2105.62</span>
            </p>
            <p class="data">
              <span>理财</span>
              <span>+1013.72</span>
            </p>
          </div>
          <div class="flot_son" v-show="boxNum == 3">
            <p>
              <span>2019年收益构成</span>
              <i @click="boxClose(0)"></i>
            </p>
            <p class="data">
              <span>股票</span>
              <span>+16753.53</span>
            </p>
            <p class="data">
              <span>基金</span>
              <span>+8572.35</span>
            </p>
            <p class="data">
              <span>债券</span>
              <span>+2005.62</span>
            </p>
            <p class="data">
              <span>理财</span>
              <span>+1413.72</span>
            </p>
          </div>
          <div class="flot_son flot_box4" v-show="boxNum == 4">
            <p>
              <span>2018年收益构成</span>
              <i @click="boxClose(0)"></i>
            </p>
            <p class="data">
              <span>股票</span>
              <span>-2070</span>
            </p>
            <p class="data">
              <span>基金</span>
              <span>-900.35</span>
            </p>
            <p class="data">
              <span>债券</span>
              <span>-407.62</span>
            </p>
            <p class="data">
              <span>理财</span>
              <span>-200.02</span>
            </p>
          </div>
       </div>
     </div>
     <div class="bg_bottom"></div>
     <div class="bg_tougu" @click="tougu('true')">投顾服务</div>
     <div v-show="screen2" class="screen">
       <div class="mengceng">
         <div class="back" @click="tougu('false')"></div>
       </div>  
     </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card5">
     <div class="content">
       <p>这是今年的荣耀时刻， </p>
       <p>跟我一起站上领奖台吧～</p>
       <p class="margin-t-10">最佳操作月份08月，</p>
       <p>共盈利29878.32元</p>
       <p>月收益率18.53%。</p>
       <div class="bg_kuisun" @click="btn_zuishuai">最衰月份</div>
     </div>
      <div class="bg_content"></div>
      <div class="content_bottom">
        <p><span>年度收益额走势</span><span>2021</span></p>
        <p>年度收益：29878.32</p>
        <div id="chart2"></div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card5_2">
      <div class="content">
       <p>这个月不太理想，</p>
       <p>坚信强者找因果，</p>
       <p>浅薄信运气～</p>
       <p class="content_text2">最衰月份05月，</p>
       <p>共盈利-18565.32元，</p>
       <p>月收益率-15.23%。</p>
      </div>
      <div class="bg_content"></div>
       <div class="bg_kuisun" @click="btn_kuisui('#card5_2')">亏损股票</div>
      <div class="content_bottom">
        <p><span>年度收益额走势</span><span>2021</span></p>
        <p>年度收益：888888.88</p>
        <div id="chart3"></div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card6">
      <div class="content">
       <p>这年你获利最多的股票还记得么？</p>
       <p>忙忙碌碌的你可能记不起来了， </p>
       <p>试着选出这支让你跳跃的股票吧～</p>
      </div>
      <div class="bg_1"></div>
      <div class="bg_2"></div>
      <div class="content_box">
        <p @click="err()" :class="{'active' : (card6Num == 0) }">中材科技</p>
        <p @click="err()" :class="{'active' : (card6Num == 1) }">比亚迪</p>
        <p @click="err()" :class="{'active' : (card6Num == 2) }">泸州老窖</p>
        <p @click="err()" :class="{'active' : (card6Num == 3) }">迈瑞医疗</p>
        <p @click="err()" :class="{'active' : (card6Num == 4) }">宁德时代</p>
        <p @click="err()" :class="{'active' : (card6Num == 5) }">通威股份</p>
        <p @click="err()" :class="{'active' : (card6Num == 6) }">隆基股份</p>
        <p @click="err()" :class="{'active' : (card6Num == 7) }">光伏ETF</p>
        <p @click="err()" :class="{'active' : (card6Num == 8) }">恩捷股份</p>
        <p @click="yes('#card6')" :class="{'active' : (card6Num == 9) }">招商银行</p>
      </div>
      
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card7">
      <div class="content">
       <p>竟然答对了</p>
       <p>看来这只股已经俘获了你的心 </p>
       <p>让我们继续吧～</p>
      </div>
      <div class="bg_1"></div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card7_2">
      <div class="content">
        <p>回答错误</p>
        <p>我说吧，你一定是太忙了～</p>
        <p>让小宝带你去看看吧！</p>
      </div>
      <div class="bg_1"></div>
      <div class="bg_btn" @click="lookYes()">查看正确答案</div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card8">
      <div class="header">年度赚钱最多的股票</div>
      <div class="top">TOP3</div>
      <div class="content">
        <div>
          <p><span></span><span>招商银行</span></p>
          <p>12131.57</p>
        </div>
        <div>
          <p><span></span><span>明阳智能</span></p>
          <p>7530.25</p>
        </div>
        <div>
          <p><span></span><span>新雷能</span></p>
          <p>6352.53</p>
        </div>
      </div>
      <div class="bg_1"></div>
      <div class="bg_kuisun" @click="btn_kuisui('#card8')">亏损股票</div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card8_2">
      <div class="header">年度亏损最多的股票</div>
      <div class="top">TOP3</div>
      <div class="content content_down">
        <div>
          <p><span></span><span>埃斯顿</span></p>
          <p>-9763.35</p>
        </div>
        <div>
          <p><span></span><span>华夏航空</span></p>
          <p>-8320.36</p>
        </div>
        <div>
          <p><span></span><span>恒生电子</span></p>
          <p>-5730.2</p>
        </div>
      </div>
      <div class="bg_1"></div>
      <div class="bg_kuisun" @click="zhenggu('true')">智能诊股</div>
      <div v-show="screen1" class="screen">
        <div class="mengceng">
         <div class="back" @click="zhenggu('false')"></div>
       </div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card9">
      <div class="content">
       <p>你在21年合计交易股票832次，</p>
       <p>盈利次数539次，</p>
       <p>年度交易成功率64.78%，</p>
       <p>新股申购1275次，</p>
       <p>中签05次，运气爆棚</p>
      </div>
      <div class="bg_1"></div>
      <div class="bg_2" @click="licai('true')">
        <img src="../../assets/img/bg103_16.png" alt="">
      </div>
      <div class="bg_3"></div>
      <div class="btn_shengou" @click="shengou('true')">今日申购</div>  
      <div v-show="screen3" class="screen shengu">
        <div class="mengceng">
         <div class="back" @click="shengou('false')"></div>
        </div>
      </div>  
      <div v-show="screen4" class="screen licai">
        <div class="mengceng">
          <div class="back" @click="licai('false')"></div>
        </div>
      </div>  
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card10">
      <div class="content">
       <p>你的年度关键字</p>
       <p class="line"></p>
       <p>“金牌操盘手”</p>
       <p>你的账户收益率在2021年战胜了97.89%的股民，操作
水平直逼王者，简直神之一手～</p>
       <p class="bottom">
         <img src="../../assets/img/bg103_18.png" alt="">
       </p>
      </div>
      <div class="person_photo">
        <img src="../../assets/img/bg103_19.png" alt="">
      </div>
      <div class="btn_share btn">立即分享</div>
      <div class="btn_save btn">保存图片</div>     
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { sleep } from "../../utils/common";
export default {
  name: "Template103",
  data() {
    return {
      isRed:true,
      boxState:false,
      boxNum:0,
      cardIndex:1, //当前card
      screen1:false,  //截图的显示隐藏
      screen2:false,
      screen3:false,
      screen4:false,
      timer4:null,    //延时器
      timer5:null,
      timer6:null,
      timer8:null,
      timer8_2:null,
      timer9:null,
      card6Num:0,
    };
  },
  mounted() {
    this.isRed = false
  },
  methods: {
    loadChart1(){
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      let option = {
        xAxis: {
          type: "category",
          data: [
            "2021.0.03",
            "2021.12.30",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#E0AD94",
            },
          },
          axisLabel: {
            color: "#E0AD94",
          },
        },
        grid: {
          top:20,
          left: 30,
          bottom: 20,
          right:10
        },
        animationDuration: 4000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#E0AD94",
            },
          },
        },
        series: [
          {
            data: [12, 23.57],
            type: "line",
            color: '#FF4A40',
            showSymbol: false,
          },
          {
            data: [10, 18.52],
            type: "line",
            color: '#3173EF',
            showSymbol: false,
          },
        ],
      };
      myChart.setOption(option);
    },
    loadChart2(){
      let myChart = this.$echarts.init(document.getElementById("chart2"));
      let option = {
        xAxis: {
          type: "category",
          data: [
            "2021.01",
            "2021.03",
            "2021.05",
            "2021.07",
            "2021.08",
            "2021.010",
            "2021.12",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#E0AD94",
            },
          },
          axisLabel: {
            color: "#E0AD94",
          },
        },
        grid: {
          top:20,
          left: 30,
          bottom: 20,
          right:10
        },
        animationDuration: 4000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#E0AD94",
            },
          },
        },
        series: [
          {
            data: [1200,1400,600,900,1800,1100,1000],
            type: "line",
            color: '#FF4A40',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                colorStops: [{
                  offset: 0,  color: '#FF4A40'  // 100% 处的颜色
                }, {
                  offset: 1, color: 'rgba(255, 74, 64, 0)' //   0% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            color: '#9aea51', //线条颜色
            smooth: true //线条平滑
          },
        ],
      };
      myChart.setOption(option);
    },
    loadChart3(){
      let myChart = this.$echarts.init(document.getElementById("chart3"));
      let option = {
        xAxis: {
          type: "category",
          data: [
            "2021.01",
            "2021.03",
            "2021.05",
            "2021.07",
            "2021.08",
            "2021.010",
            "2021.12",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#E0AD94",
            },
          },
          axisLabel: {
            color: "#E0AD94",
          },
        },
        grid: {
          top:20,
          left: 30,
          bottom: 20,
          right:10
        },
        animationDuration: 4000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#E0AD94",
            },
          },
        },
        series: [
          {
            data: [1200,1400,600,900,1800,1100,1000],
            type: "line",
            color: '#FF4A40',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                colorStops: [{
                  offset: 0,  color: '#FF4A40'  // 100% 处的颜色
                }, {
                  offset: 1, color: 'rgba(255, 74, 64, 0)' //   0% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            color: '#9aea51', //线条颜色
            smooth: true //线条平滑
          }
        ],
      };
      myChart.setOption(option);
    },
    playBgAudio() {
      this.$refs.bgAudio.volume = 0.1; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
    },
    playCard1(suspend){
      this.cardIndex = 1
      return new Promise(async (resolve, reject) => {
        $("#card1").addClass("none");
        resolve();
      });
    },
    playCard2(suspend){
      this.cardIndex = 2
      return new Promise(async (resolve, reject) => {
        $("#card2").removeClass("none");
        await sleep(5000);
        $("#card2").addClass("none");
        resolve();
      });
    },
    playCard3(suspend){
      this.cardIndex = 3
      return new Promise(async (resolve, reject) => {
        $("#card3").removeClass("none");
        this.loadChart1()
        await sleep(5000);
        $("#card3").addClass("none");
        resolve();
      });
    },
    playCard4(suspend){
      this.cardIndex = 4
      $("#card4").removeClass("none");
      $('.bg_tougu').animate({right:'10px'},2000)
        if (suspend) {
          return;
        }    
      return new Promise( (resolve, reject) => {
        $("#card4").removeClass("none");
        if (suspend) {
          return;
        }
        this.timer4 = setTimeout(()=>{
          $("#card4").addClass("none");
          resolve();
        },5000)       
      });
    },
    playCard5(suspend){
      this.cardIndex = 5
      this.timer4 = null;
      clearTimeout(this.timer4)
      return new Promise(async (resolve, reject) => {
        $("#card5").removeClass("none");
        $('#card5 .bg_kuisun').animate({right:'10px'},1000)
        this.loadChart2()
        if (suspend) {
          return;
        }
        this.timer5 = setTimeout(()=>{
          $("#card5").addClass("none");
          resolve();
        },5000)       
      });
    },
    playCard6(suspend){
      this.cardIndex = 6
      return new Promise(async (resolve, reject) => {
        $("#card6").removeClass("none");
        if (suspend) {
          return;
        }  
        this.timer6 = setInterval(() => {
            this.card6Num ++;
            if(this.card6Num>8){
              this.card6Num = 0
            }
        }, 1000);
      });
    },
    playCard7(suspend){
      this.cardIndex = 7
      clearInterval(this.timer6)
      this.timer6 = null
      return new Promise(async (resolve, reject) => {
        $("#card7").removeClass("none");
        if (suspend) {
          return;
        }
        await sleep(3000);
        if(this.cardIndex == 7){
          $("#card7").addClass("none");
          resolve();
         }else{
           return 
         }   
      });
    },
    playCard8(suspend){
      this.cardIndex = 8
      return new Promise(async (resolve, reject) => {
        $("#card8").removeClass("none");
        $('#card8 .bg_kuisun').animate({right:"10px"},1000)
        if (suspend) {
          return;
        }
        this.timer8 = setTimeout(()=>{
          $("#card8").addClass("none");
          resolve();
        },5000) 
      });
    },
    playCard9(suspend){
      this.cardIndex = 9
      return new Promise(async (resolve, reject) => {
        $("#card9").removeClass("none");
        $('#card9 .btn_shengou').animate({right:"10px"},1000)
        $('#card9 .bg_2').animate({right:"10px"},1000)
        if (suspend) {
          return;
        }
        this.timer9 = setTimeout(()=>{
          $("#card9").addClass("none");
          resolve();
        },5000)  
      });
    },
    playCard10(suspend){
      this.cardIndex = 10
      return new Promise(async (resolve, reject) => {
        $("#card10").removeClass("none");
        if (suspend) {
          return;
        }
        resolve();
      });
    },
    async startPlay() {
      this.playBgAudio();
      await this.playCard1();
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
    },
    // 最衰月份
    async btn_zuishuai(){
      this.cardIndex = 52
      clearTimeout(this.timer5)
      this.timer5 = null
       $("#card5").addClass("none");
       $("#card5_2").removeClass("none");
       $('#card5_2 .bg_kuisun').animate({right:'10px'},1000)
       this.loadChart3()
       await sleep(5000);
       if( this.cardIndex == 52){
         $("#card5_2").addClass("none");
          await this.playCard6();
          await this.playCard7();
          await this.playCard8();
          await this.playCard9();
          await this.playCard10();
       }else{
        //  点击了card5_2的亏损股票按钮
         $("#card5_2").addClass("none");
         $("#card8_2").removeClass("none");
         $('#card8_2 .bg_kuisun').animate({right:"10px"},1000)
          this.timer8_2 = setTimeout(()=>{
              this.playCard8_2All()
          },5000)
          
       }     
    },
    async playCard8_2All(){
      $("#card8_2").addClass("none");
      await this.playCard9();
      await this.playCard10();
    },
    async playCard4All(){
      $("#card4").addClass("none");
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
    },
    async playCard8All(){
      $("#card8").addClass("none");
      await this.playCard9();
      await this.playCard10();
    },
    // 亏损股票
    async btn_kuisui(dom){
      this.cardIndex = 82
       if(dom == '#card5_2'){
         $("#card5_2").addClass("none");
          $("#card8_2").removeClass("none");
          $('#card8_2 .bg_kuisun').animate({right:"10px"},1000)
          this.timer8_2 = setTimeout(()=>{
              this.playCard8_2All()
          },5000)
       }else{
        //  从8_1进来的
        clearTimeout(this.timer8)
        this.timer8 = null
          $("#card8").addClass("none");
          $("#card8_2").removeClass("none");
          $('#card8_2 .bg_kuisun').animate({right:"10px"},1000)
          this.timer8_2 = setTimeout(()=>{
            this.playCard8_2All()
          },5000)
       }
    },
    // 选错答案
    async err(){
      this.cardIndex = 72
      clearInterval(this.timer6)
      this.timer6 = null
      $("#card6").addClass("none");
      $("#card7_2").removeClass("none");
      $('.bg_btn').animate({right:"50%"},1000)
    },
    async yes(dom){
       this.cardIndex = 7
      clearInterval(this.timer6)
      this.timer6 = null
       if(dom == '#card7_2'){
         $(dom).addClass("none");

       }else{
          $(dom).addClass("none");
          $("#card7").removeClass("none");
          await sleep(3000);
          $("#card7").addClass("none");
          await this.playCard8();
          await this.playCard9();
          await this.playCard10();
       }
    },
    // 查看正确答案
    async lookYes(){
      this.cardIndex = 8
      $("#card7_2").addClass("none");
      $("#card8").removeClass("none");
       $('#card8 .bg_kuisun').animate({right:"10px"},1000)
      this.timer8 = setTimeout(()=>{
        this.playCard8All()
      },5000)
    },
    boxClick(num){
      this.boxNum = num
      this.boxState=true
      clearTimeout(this.timer4)
      this.timer4 = null
    },
    boxClose(num){
      this.boxNum = num
      this.boxState=false
      this.timer4 = setTimeout(()=>{
        this.playCard4All()
      },5000)
    },
    // 投顾服务
    tougu(par){
      this.cardIndex = 0
      if(par == 'true'){
        this.screen2 = true
        clearTimeout(this.timer4)
        this.timer4 = null
      }else{
        this.screen2 = false
        this.timer4 = setTimeout(()=>{
          this.playCard4All()
        },5000)
      }
    },
    // 智能诊股
    zhenggu(par){
      this.cardIndex = 0
      if(par == 'true'){
        this.screen1 = true
        clearTimeout(this.timer8_2)
        this.timer8_2 = null
      }else{
        this.screen1 = false
        this.timer8_2 = setTimeout(()=>{
            this.playCard8_2All()
        },5000)
      }
    },
    // 今日申购
    shengou(par){
      this.cardIndex = 0
      if(par == 'true'){
        this.screen3 = true
        clearTimeout(this.timer9)
        this.timer9 = null
      }else{
        this.screen3 = false
        this.timer9 = setTimeout(()=>{
            $("#card9").addClass("none");
            $("#card10").removeClass("none");
        },5000)
      }
    },
    // 点击理财商城
    licai(par){
      this.cardIndex = 0
      if(par == 'true'){
        this.screen4 = true
        clearTimeout(this.timer9)
        this.timer9 = null
      }else{
        this.screen4 = false
        this.timer9 = setTimeout(()=>{
            $("#card9").addClass("none");
            $("#card10").removeClass("none");
        },5000)
      }
    },
  },
};
</script>
<style lang="less" scoped>
.logo-box{
  bottom: 3.5%;
  width: 8.3rem;
}
.card{
  color: #fff;
  background-color: transparent;
  overflow: hidden;
}
.redbg{
  background: url("../../assets/img/bg103_4.png") no-repeat center center;
  background-size: cover;
}
.isyello{
  background: url("../../assets/img/bg103_7.png") no-repeat center center;
  background-size: cover;
}
.screen{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  .back{
    position: absolute;
    left: 2rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    background: url("../../assets/img/back.png") no-repeat center center;
    background-size: cover;
  }
}
.mengceng{
  width: 100%;
  height: 4rem;
  position: absolute;
  background: #fff;
  left: 0;
  top: 0;
}
#card1 {
  background: url("../../assets/img/bg103_1.png") no-repeat center center;
  background-size: cover;
  .bg_content{
    height: 80%;
    background: url("../../assets/img/bg103_2.png") no-repeat center center;
    margin-top: 4rem;
    background-size: cover;
    overflow: hidden;
    .content_top{
      width: 22.4rem;
      height: 5.1rem;
      margin: 5rem auto 0;
      img{
        width: 100%;
        height: auto;
      }
    }
    .content_middle{
      width: 29rem;
      height: 10.6rem;
      margin: 6rem auto;
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
      color: #493E38;
      h3{
        font-size: 4rem;
        margin-bottom: 1rem;
      }
    }
    .content_btn{
      width: 24rem;
      height: 5.6rem;
      text-align: center;
      line-height: 5.6rem;
      border-radius:10rem ;
      background-color: #E9560D ;
      color: #fff;
      margin: auto;
      font-size: 2.2rem;
    }
  }
}
#card2{
  .content{
    padding: 4rem 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
  }
  .content_bg{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24.9rem;
    img{
      width: 100%;
      height: auto;
    }
  }
}
#card3{
  .content_bg{
    position: absolute;
    width: 100%;
    top: 3.3rem;
    bottom: 0;
    background: url("../../assets/img/bg103_6.png") no-repeat center center;
    background-size: cover;
    z-index: -1;
  }
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 55%;
  }
  .content_bottom{
    width: 30rem;
    height: 18.6rem;
    margin: 1rem auto 0;
    background: #F5F2EA;
    border-radius: 1rem;
    border: 1px solid #FFF8E6;
    color: #252525;
    padding: 1rem;
    >p:first-of-type{
      display: flex;
      justify-content: space-between;
      >span:first-of-type{
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    >p:nth-of-type(2){
      margin-top: .4rem;
      font-size: 1rem;
      >span:nth-of-type(1){
        color: #3173EF;
      }
      >span:nth-of-type(2){
        color: #FF4A40;
        margin-left: 4.3rem;
      }
    }
    #chart1{
      width: 100%;
      height: 14rem;
    }
  }
}
#card4{
  .content_top{
    position: absolute;
    width: 100%;
    height: 5.9rem;
    top: 0;
    img{
      width: 100%;
      height: auto;
    }
  }
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 18%;
  }
  .content_box{
    padding: 0 2rem;
    margin-top: 2rem;
    position: relative;
    >div{
      margin-top: 1.6rem;
    }
    .bg_progess{
      height: 2.9rem;
      line-height: 2.9rem;
      background: rgba(255,255,255,0.24);
      border-radius: .4rem;
      margin-top: 0.6rem;
      position: relative;
      >p{
        text-align: right;
        width: 80%;
        height: 100%;
        background: #E9560D;
        padding-right: .8rem;
        border-radius: .4rem;
      }
      i.down{
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #fff;
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
      i.power{
        width: 5rem;
        height: 5rem;
        background: url("../../assets/img/bg103_25.gif") no-repeat center center;
        background-size: cover;
        position: absolute;
        right: -1rem;
        top: -1rem;
        opacity: 0.4;
      }
    }
    >div:nth-of-type(4){
      .bg_progess{
        >p{
          background: #2F932F;
        }
      }
    }
    .flot_box{
      position: absolute;
      left: 2rem;
      top: 0;
      width: 30rem;
      height: 23rem;
      border: 2px solid #FF9F01;
      border-radius: 1rem;
      background: #FFF4E0;
      color: #000;
      padding: 1rem;
      z-index: 10;
      p{
        display: flex;
        justify-content: space-between;      
      }
      p.data{
        margin-top: 3rem;
        span:nth-of-type(2){
          color: #E9560D;
        }
      }
      p:first-of-type{
        i{
          width: 2.1rem;
          height: 2.1rem;
          background: url("../../assets/img/bg103_20.png") no-repeat center center;
          background-size: cover;
        }
        
      }
    }
    .flot_box4{
      p.data{
        span:nth-of-type(2){
          color: #2F932F;
        }
      }
    }
  }
  .bg_bottom{
    position: absolute;
    width: 100%;
    height: 23.8rem;
    bottom: 0;
    background: url("../../assets/img/bg103_9.png") no-repeat center center;
    background-size: cover;
    z-index: -1;
  }
  .bg_tougu{
    position: absolute;
    bottom: 32%;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
    z-index: 1;
    // background: url("../../assets/img/bg103_10.png") no-repeat center center;
    // background-size: cover;
  }
  .screen{
    background: url("../../assets/img/bg103_23.png") no-repeat center center;
    background-size: cover;
  }
}
#card5{
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 18%;
    position: relative;
  }
  .bg_content{
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 44.1rem;
    background: url("../../assets/img/bg103_11.png") no-repeat center center;
    background-size: cover;
  }
  .content_bottom{
    // width: 30rem;
    height: 18.6rem;
    position: absolute;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    background: #F5F2EA;
    border-radius: 1rem;
    border: 1px solid #FFF8E6;
    color: #252525;
    padding: 1rem;
    >p:first-of-type{
      display: flex;
      justify-content: space-between;
      >span:first-of-type{
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    >p:nth-of-type(2){
      margin-top: .4rem;
      font-size: 1rem;
      color: #FF4A40;
    }
    #chart2{
      width: 100%;
      height: 14rem;
    }
  }
  .bg_kuisun{
    position: absolute;
    top: 6rem;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
  }
}
#card5_2{
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 18%;
    .content_text2{
      margin-top: 6rem;
    }
  }
  .bg_content{
    position: absolute;
    width: 19.6rem;
    top: 1rem;
    right: 2.6rem;
    height: 23.4rem;
    background: url("../../assets/img/bg103_12.png") no-repeat center center;
    background-size: cover;
  }
  .bg_kuisun{
    position: absolute;
    bottom: 52%;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
  }
  .content_bottom{
    // width: 30rem;
    height: 18.6rem;
    position: absolute;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    background: #F5F2EA;
    border-radius: 1rem;
    border: 1px solid #FFF8E6;
    color: #252525;
    padding: 1rem;
    >p:first-of-type{
      display: flex;
      justify-content: space-between;
      >span:first-of-type{
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    >p:nth-of-type(2){
      margin-top: .4rem;
      font-size: 1rem;
      color: #FF4A40;
    }
    #chart3{
      width: 100%;
      height: 14rem;
    }
  }
}
#card6{
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 16%;
  }
  .bg_1{
    position: absolute;
    width: 100%;
    top: 0;
    height: 5.9rem;
    background: url("../../assets/img/bg103_8.png") no-repeat center center;
    background-size: cover;
  }
  .bg_2{
    position: absolute;
    width: 26.1rem;
    bottom: 0;
    left: 2.5rem;
    height: 29.4rem;
    background: url("../../assets/img/bg103_13.png") no-repeat center center;
    background-size: cover;
  }
  .content_box{
    position: relative;
    p{
      position: absolute;
      padding: 0.5rem 0;
      width: 7.3rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      border-radius: 2rem;
    }
    p.active{
      background: #FFE98B;
      color: #E9560D;
    }
    p:nth-of-type(1){
      left: 13.1rem;
      top: 3rem;
    }
    p:nth-of-type(2){
      left: 3.2rem;
      top: 5rem;
    }
    p:nth-of-type(3){
      right: 4rem;
      top: 5rem;
    }
    p:nth-of-type(4){
      left: 4.2rem;
      top: 9rem;
    }
    p:nth-of-type(5){
      right: 5.5rem;
      top: 12rem;
    }
    p:nth-of-type(6){
      left: 2.7rem;
      top: 13rem;
    }
    p:nth-of-type(7){
      left: 14.4rem;
      top: 14.5rem;
    }
    p:nth-of-type(8){
      left: 8rem;
      top: 18rem;
    }
    p:nth-of-type(9){
      right: 7.1rem;
      top: 18rem;
    }
    p:nth-of-type(10){
      right: 13.3rem;
      top: 8.5rem;
    }
  }
}
#card7,#card7_2{
  .content{
    width: 23rem;
    height: 13rem;
    line-height: 4.2rem;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 45%;
    >p:nth-of-type(1){
      font-size: 2rem;
      font-weight: bold;
    }
  }
  .bg_1{
    position: absolute;
    width: 16.8rem;
    top: 6rem;
    left: 4.3rem;
    height: 18.9rem;
    background: url("../../assets/img/bg103_13.png") no-repeat center center;
    background-size: cover;
  }
  .bg_btn{
    width: 13.3rem;
    height: 4.2rem;
    line-height: 4.2rem;
    text-align: center;
    position: absolute;
    right: -20%;
    transform: translateX(50%);
    bottom: 23%;
    background: linear-gradient(171deg, #FFBB0A 0%, #FF9D00 100%);
    color: #000;
    border-radius: 26px;
  }
}
#card8,#card8_2{
  .header{
    font-size: 3.2rem;
    margin: 6rem 0 1rem 2rem;
  }
  .top{
    margin-left: 2rem;
  }
  .content{
    >div{
      width: 30rem;
      padding: 0 1rem;
      height: 4.2rem;
      margin:4rem 0 0 2rem ;
      border-radius: 2rem;
      background: #E9560D;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .content_down{
    >div{
      background: #2F932F;
    }
  }
  .bg_1{
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 20.5rem;
    background: url("../../assets/img/bg103_14.png") no-repeat center center;
    background-size: cover;
  }
  .bg_kuisun{
    position: absolute;
    bottom: 32%;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
  }
  .screen{
    background: url("../../assets/img/bg103_21.png") no-repeat center center;
    background-size: cover;
  }
}
#card9{
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 16%;
  }
  .btn_shengou{
    position: absolute;
    top: 22.5rem;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
  }
  .bg_1{
    position: absolute;
    width: 5.1rem;
    right: 2.5rem;
    top: 11.7rem;
    height: 10.7rem;
    background: url("../../assets/img/bg103_15.png") no-repeat center center;
    background-size: cover;
  }
  .bg_2{
    position: absolute;
    width: 14.5rem;
    right: -10rem;
    top: 27.5rem;
    height: 5.5rem;
    img{
      width: 100%;
      height: auto;
    }
  }
  .bg_3{
    position: absolute;
    width: 30.8rem;
    left: 2.6rem;
    bottom: 6.3rem;
    height: 26.5rem;
    background: url("../../assets/img/bg103_17.png") no-repeat center center;
    background-size: cover;
  }
  .shengu{
    background: url("../../assets/img/bg103_22.png") no-repeat center center;
    background-size: cover;
  }
  .licai{
    background: url("../../assets/img/bg103_24.png") no-repeat center center;
    background-size: cover;
  }
}
#card10{
  .content{
    width: 32rem;
    height: 45.4rem;
    background: #FAF4F4;
    margin: 4.5rem auto 0;
    border-radius: 4.2rem 0px 4.2rem 0px;
    position: relative;
    color: #462F24;
    >p:nth-of-type(1){
      position: absolute;
      left: 50%;
      width: 18.6rem;
      transform: translateX(-50%);
      top: 6rem;
      font-size: 2.6rem;
      z-index: 10;
    }
    .line{
      position: absolute;
      left: 50%;
      width: 18.6rem;
      height: 1rem;
      transform: translateX(-50%);
      top: 8.5rem;
      background: #FFA54D;
      z-index: 1;
    }
    >p:nth-of-type(3){
      position: absolute;
      width: 100%;
      text-align: center;
      top: 16.6rem;
      font-size: 4rem;
      color: #483112;
    }
    >p:nth-of-type(4){
      position: absolute;
      width: 27.8rem;
      left: 2rem;
      top: 24.1rem;
      line-height: 2.1rem;
      color: #E9560D;
    }
    .bottom{
      width: 100%;
      height: 9.6rem;
      position: absolute;
      bottom: 0;
      background: #FFFDFD;
      border-radius: 0px 0px 4.2rem 0px;
      text-align: center;
      img{
        width: 28.2rem;
        height: auto;
        margin-top: 1.9rem;
      }
    }
    
  }
  .person_photo{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 1.5rem;
      width: 6rem;
      height: 6rem;
      img{
        width: 100%;
        height: auto;
      }
    }
  .btn{
    position: absolute;
    width: 13.3rem;
    height: 4.2rem;
    text-align: center;
    line-height: 4.2rem;
    border-radius: 2.6rem;
    color: #000;
  }
  .btn_share{
    left: 2rem;
    bottom: 5.6rem;
    background: #FFE1B1;
  }
  .btn_save{
    right: 2rem;
    bottom: 5.6rem;
    background: #FF9D00;
  }
}
</style>